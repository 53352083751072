import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Modal from "react-modal"
import {
  container,
  container__content,
  container__content__event,
  container__content__event__grid,
  container__modal,
  container__modal__exit,
} from "./style/gallery.module.scss"
import { ResizedImage } from "@components/shared"
import CloseIcon from "./icons/close.svg"

const customStyles = {
  overlay: {
    zIndex: 50,
    backgroundColor: "rgba(0,0,0,0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "none",
    border: "none",
    padding: "1rem",
  },
}

const Gallery: React.FC = (): JSX.Element => {
  Modal.setAppElement("#___gatsby")
  const [isOpen, setOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const { WP } = useStaticQuery(graphql`
    {
      WP {
        page(id: "cG9zdDoxMjg=") {
          ACF_Gallery {
            events {
              photos {
                photo {
                  sourceUrl
                  mediaDetails {
                    height
                    width
                  }
                  srcSet
                }
              }
              title
            }
          }
        }
      }
    }
  `)

  const { events } = WP.page.ACF_Gallery

  const openModal = (photo) => {
    setModalData(photo)
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <section className={container}>
      <div className={container__content}>
        {events.map((el, i) => (
          <div key={i} className={container__content__event}>
            <h3>{el.title}</h3>
            <div className={container__content__event__grid}>
              {el.photos.map(({ photo }, i, array) => (
                <div onClick={() => openModal(photo.sourceUrl)}>
                  <ResizedImage {...photo} loading="lazy" />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
        <div className={container__modal}>
          <button className={container__modal__exit} onClick={closeModal}>
            <img src={CloseIcon} />
          </button>
          <img src={modalData} alt="gallery-photo" />
        </div>
      </Modal>
    </section>
  )
}

export default Gallery
