import React, { useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import * as Yup from "yup"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import {
  container,
  container__content__header,
  container__content__accordion,
  container__content,
  container__content__accordion__button,
  container__content__accordion__panel,
  container__content__accordion__item,
} from "./style/program.module.scss"
import ProgramIcon from "./icons/program.svg"

const Program: React.FC = (): JSX.Element => {
  const programRef = useRef(null)

  const { WP } = useStaticQuery(graphql`
    {
      WP {
        page(id: "cG9zdDo5ODk=") {
          ACF_Events {
            events {
              title
              single {
                url
                title
                text
                number
                fieldGroupName
                eventName
                date
                contactMessage
                link
              }
            }
          }
        }
      }
    }
  `)

  const { events } = WP.page.ACF_Events

  return (
    <section id="program" className={container} ref={programRef}>
      <div className={container__content}>
        <div className={container__content__header}>
          <img src={ProgramIcon} alt="calendar" loading="lazy" />
          <h3>Program</h3>
        </div>
        <Accordion
          allowZeroExpanded
          className={container__content__accordion}
          // preExpanded={["program-0"]}
          onChange={(res) => {
            const doc = document.querySelector("#program")
            doc?.scrollIntoView()
          }}
        >
          {events.map((day, i) => (
            <AccordionItem
              key={day.title}
              uuid={`program-${i}`}
              id={`program-${i}`}
              className={container__content__accordion__item}
            >
              <AccordionItemHeading>
                <AccordionItemButton
                  className={container__content__accordion__button}
                >
                  {day.title}
                </AccordionItemButton>
              </AccordionItemHeading>
              {day.single.map((event, index) => (
                <AccordionItemPanel
                  key={index}
                  className={container__content__accordion__panel}
                >
                  <h5>{event.title}</h5>
                  <h4>
                    <a href={event.url} target="_blank">
                      {event.eventName}
                    </a>
                  </h4>
                  <h6 dangerouslySetInnerHTML={{ __html: event.text }}></h6>
                  <p>{event.date}</p>
                  <small>
                    {event.contactMessage}{" "}
                    <a href={`tel:${event.number}`}>{event.number}</a>
                    <a href={event.link} target="_blank">
                      {event.link}
                    </a>
                  </small>
                </AccordionItemPanel>
              ))}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  )
}

export default Program
