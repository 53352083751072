import React from "react"
import { useLocation } from "@reach/router"
import {
  footer,
  footer__container,
  footer__localhost,
  footer__container__section,
} from "./footer.module.scss"
import Logo from "../../standard/Home/icons/theoldhempLOGOtext.svg"
import { useStaticQuery, graphql } from "gatsby"

const Footer: React.FC = (): JSX.Element => {
  const { pathname } = useLocation()
  const { WP } = useStaticQuery(graphql`
    {
      WP {
        page(id: "cG9zdDo5ODk=") {
          ACF_Contact {
            contact {
              addressOne
              addressTwo
              email
              name
              telephonNumber
            }
          }
        }
      }
    }
  `)

  const { contact } = WP.page.ACF_Contact

  return (
    <footer id="kontakt" className={footer}>
      <div className={footer__container}>
        {contact.map(
          ({ name, email, telephonNumber, addressTwo, addressOne }, i) => (
            <div key={i} className={footer__container__section}>
              {name && <span>{name}</span>}
              {email && (
                <span>
                  <a href={`mailto:${email}`}>{email}</a>
                </span>
              )}
              {telephonNumber && (
                <span>
                  <a href={`tel:${telephonNumber}`}>{telephonNumber}</a>
                </span>
              )}
              {addressOne && <span>{addressOne}</span>}
              {addressTwo && <span>{addressTwo}</span>}
            </div>
          )
        )}
      </div>
      <div className={footer__localhost}>
        Za obsługę cyfrową Festiwalu Wina odpowiada{" "}
        <a href="https://localhost-group.com/" target="_blank">
          Localhost Group sp. z.o.o.
        </a>
      </div>
    </footer>
  )
}

export default Footer
