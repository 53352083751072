import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import {
  overlay,
  modal,
  modal__buttons,
  modal__buttons__accept,
  modal__buttons__decline,
} from "./style/ageverify.module.scss"

const AgeVerify: React.FC = (): JSX.Element => {
  const [isOpen, setOpen] = useState(false)

  const closeModal = () => {
    setOpen(false)
  }

  const acceptAge = () => {
    localStorage.setItem("ageVerify", "true")
    closeModal()
  }

  const declineAge = () => {
    if (typeof window !== "undefined") {
      window.location.href = "http://google.com"
    }
  }

  //   LOGIKA WYŚWIETLANIA MODALA Z NEWSLETTEREM
  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      if (!localStorage.getItem("ageVerify")) setOpen(true)
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={modal}
      overlayClassName={overlay}
    >
      <h2>Aby korzystać z tej strony musisz być osoba pełnoletnią.</h2>
      <h3>Czy jesteś osobą pełnoletnią?</h3>
      <div className={modal__buttons}>
        <button onClick={declineAge} className={modal__buttons__decline}>
          Nie
        </button>
        <button onClick={acceptAge} className={modal__buttons__accept}>
          Tak
        </button>
      </div>
    </Modal>
  )
}

export default AgeVerify
