import React, { useEffect, useRef } from "react"
import { AppCtx, useContextState } from "@components/contexted"
import {
  container,
  container__header,
  container__map,
  container__map__iframe,
} from "./style/googlemap.module.scss"
import MapIcon from "./icons/map.svg"

const GoogleMap: React.FC = (): JSX.Element => {
  const { imBusy } = useContextState(AppCtx, "imBusy")
  const mapRef = useRef()

  useEffect(() => {
    mapRef.current.addEventListener("mouseover", mapListener)
  }, [])

  const mapListener = (e) => {
    const frame = document.createElement("iframe")
    frame.src =
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d79969.5286947658!2d22.49373081483533!3d51.218195621441616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472257141e154061%3A0x5528ee7af6e8e95f!2sLublin!5e0!3m2!1spl!2spl!4v1627917179716!5m2!1spl!2spl"
    frame.className = container__map__iframe
    frame.id = "gmap_canvas"
    frame.frameBorder = "0"
    mapRef.current.appendChild(frame)
    mapRef.current.removeEventListener("mouseover", mapListener)
  }

  return (
    <section className={container}>
      <div className={container__header}>
        <img src={MapIcon} alt="map" loading="lazy" />
        <h3>Lublin</h3>
      </div>
      <div ref={mapRef} className={container__map}></div>
    </section>
  )
}

export default GoogleMap
