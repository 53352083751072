import React, { useState, useEffect, useCallback, useContext } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { WindowCtx } from "@components/contexted"
import { INavItem } from "./types"
import Menu from "./Menu"
import { AnimatedLink } from "@components/shared"
import Hamburger from "./Hamburger"
import Instagram from "../../standard/Home/icons/Animated/Instagram"
import Fb from "../../standard/Home/icons/Animated/Fb"
import Yt from "../../standard/Home/icons/Animated/Yt"
import { useLocation } from "@reach/router"
import Modal from "react-modal"

import {
  header,
  header__small,
  nav,
  nav__container,
  nav__container__logo,
  nav__container__links,
  nav__container__link,
  bottom,
  logo,
  nav__container__dropdown,
  nav__container__dropdown__menu,
  nav__container__dropdown__menu__link,
} from "./header.module.scss"

Modal.setAppElement("#___gatsby")

const navLinks: Array<INavItem> = [
  {
    name: "O festiwalu",
    url: "/",
  },
  {
    name: "Program",
    url: "/#program",
  },
  {
    name: "Partnerzy",
    url: "/partnerzy",
  },
  {
    name: "Winnice i Wystawy",
    url: "/winnice",
  },
  // {
  //   name: "Galeria",
  //   url: "/galeria",
  // },

  {
    name: "Kontakt",
    url: "/#kontakt",
  },
]

const subNavLinks: Array<INavItem> = [
  { name: "Winnice 2021", url: "/home-2021/winnice-2021" },
  { name: "Galeria 2021", url: "/home-2021/galeria-2021" },
  { name: "Winnice 2020", url: "/home-2020/winnice-2020" },
  { name: "Galeria 2020", url: "/home-2020/galeria-2020" },
  {
    name: "Winnice 2018",
    url: "/home-2018/winnice-2018",
  },
  {
    name: "Galeria 2018",
    url: "/home-2018/galeria-2018",
  },
]

const useScrollHeight = () => {
  const [small, setSmall] = useState(false)
  let scrollHeight = null
  if (typeof window !== "undefined") {
    scrollHeight = window.scrollY
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 150) {
        setSmall(true)
      } else {
        setSmall(false)
      }
    }
  }, [scrollHeight])
  return small
}
const Header: React.FC = (): JSX.Element => {
  const { y } = useContext(WindowCtx)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [isMenuOpen])

  const handleKeydown = useCallback((e) => {
    if (!isMenuOpen) return
    if (e.which === 27 || e.key === "Escape") toggleMenu()
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", (e: Event) => handleKeydown(e))
    return () =>
      window.removeEventListener("keydown", (e: Event) => handleKeydown(e))
  }, [])

  return (
    <header className={useScrollHeight() ? `${header__small}` : `${header}`}>
      <Helmet></Helmet>

      <nav className={nav}>
        <div className={nav__container}>
          <div className={nav__container__logo}>
            <Link to={"/"}>
              <img
                src={
                  "http://fw.api.localhost-group.com/wp-content/uploads/2021/05/festiwal_wina_logo.png"
                }
                alt="festiwal-wina"
              ></img>
            </Link>
          </div>
          <ul className={nav__container__links}>
            {navLinks.map((link, i) => (
              <li key={i}>
                <Link
                  to={link.url}
                  className={
                    location.pathname === link.url ||
                    location.pathname + location.hash === link.url
                      ? `${nav__container__link} ${bottom}`
                      : nav__container__link
                  }
                >
                  {link.name}
                </Link>
              </li>
            ))}
            <Link to="/home-2018" className={nav__container__dropdown}>
              Archiwum
              <ul className={nav__container__dropdown__menu}>
                {subNavLinks.map((link, i) => (
                  <li key={i}>
                    <Link
                      to={link.url}
                      className={nav__container__dropdown__menu__link}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Link>
          </ul>
          {/* <div className={nav__container__medias}>
            <a
              href="https://www.facebook.com/The-Old-Hemp-102812905028116"
              target="_blank"
              className={nav__container__medias__left__icon}
            >
              <Fb />
            </a>
          </div> */}
        </div>
        <div className={logo}>
          <Link to={"/"}>
            <img
              src={
                "http://fw.api.localhost-group.com/wp-content/uploads/2021/05/festiwal_wina_logo.png"
              }
              alt="leaf"
            ></img>
          </Link>
        </div>
      </nav>
      <Hamburger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <Menu
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        closeMenu={() => setIsMenuOpen(false)}
      />
    </header>
  )
}

export default Header
