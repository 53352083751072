import React from "react"
import Helmet from "react-helmet"

const siteBasic = {
  title: "",
  description: "",
  lang: "",
}

const seoBasic = {
  title: "Page",
  opengraphTitle: "",
  metaKeywords: "",
  metaDesc: "",
  metaRobotsNofollow: "",
  metaRobotsNoindex: "",
  opengraphDescription: "",
  opengraphImage: null,
}

function Head({ siteMetaData = {}, seo = {}, ogType = "website", location }) {
  console.log("head", seo)
  return (
    <Helmet
      htmlAttributes={{
        lang: siteMetaData.language || siteBasic.lang,
      }}
      title={`${seo.title}`}
      meta={[
        {
          name: "description",
          content: seo.opengraphDescription || siteMetaData.description,
        },
        {
          name: "keywords",
          content: seo.focuskw || "",
        },
        {
          property: "og:title",
          content: seo.title || siteMetaData.title || siteBasic.description,
        },
        {
          property: "og:description",
          content:
            seo.opengraphDescription ||
            siteMetaData.description ||
            siteBasic.description,
        },
        {
          property: "og:image",
          content: seo.opengraphImage ? seo.opengraphImage.sourceUrl : "",
        },
        {
          property: "og:image:alt",
          content: seo.title,
        },
        {
          property: "og:type",
          content: ogType,
        },
        {
          property: "og:url",
          content: location.href,
        },
      ]}
    >
      {/* favicon */}
      <link
        rel="icon"
        href="https://fw.api.localhost-group.com/wp-content/uploads/2021/05/favicon.png"
      />

      <link rel="preconnect dns-prefetch" href="https://cdnjs.cloudflare.com" />
      <link
        rel="preload"
        as="style"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        onload="this.rel='stylesheet'"
      />
      <link
        rel="preload"
        as="style"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        onload="this.rel='stylesheet'"
      />
      {/* <script
        async
        defer
        type="text/javascript"
        src="https://apis.google.com/js/api.js"
      /> */}
      {/* FACEBOOK */}
      {/* <script
        async
        defer
        crossorigin="anonymous"
        src="https://connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v10.0"
        nonce="2kvxfFk7"
      ></script> */}
    </Helmet>
  )
}

export default Head
