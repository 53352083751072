import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import {
  container,
  container__content__header,
  container__content__accordion,
  container__content,
  container__content__accordion__button,
  container__content__accordion__panel,
  container__content__accordion__item,
  item,
} from "./style/program.module.scss"
import ProgramIcon from "./icons/program.svg"

const Program: React.FC = (): JSX.Element => {
  const { WP } = useStaticQuery(graphql`
    {
      WP {
        page(id: "cG9zdDoxNjA=") {
          ACF_Events {
            events {
              single {
                contactMessage
                date
                eventName
                number
                title
                url
                text
              }
              title
            }
          }
        }
      }
    }
  `)

  const { events } = WP.page.ACF_Events

  return (
    <section id="program" className={container}>
      <div className={container__content}>
        <div className={container__content__header}>
          <img src={ProgramIcon} alt="calendar" loading="lazy" />
          <h3>Program</h3>
        </div>
        <Accordion
          allowZeroExpanded
          className={container__content__accordion}
          preExpanded={["0"]}
        >
          {events.map((day, i) => (
            <AccordionItem
              key={day.title}
              uuid={`${i}`}
              className={container__content__accordion__item}
            >
              <AccordionItemHeading>
                <AccordionItemButton
                  className={container__content__accordion__button}
                >
                  {day.title}
                </AccordionItemButton>
              </AccordionItemHeading>
              {day.single.map((event) => (
                <AccordionItemPanel
                  className={container__content__accordion__panel}
                >
                  <h5>{event.title}</h5>
                  <h4>
                    <a href={event.url} target="_blank">
                      {event.eventName}
                    </a>
                  </h4>
                  <p>{event.date}</p>
                  <p>{event.text}</p>
                  <small>
                    {event.contactMessage}{" "}
                    <a href={`tel:${event.number}`}>{event.number}</a>
                  </small>
                </AccordionItemPanel>
              ))}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  )
}

export default Program
