import React from "react"
import { useLocation } from "@reach/router"
import { Header, Head, Footer } from "@components/layout"
import { AgeVerify } from "@components/standard"
import ThirdPartyScripts from "./Scripts"
const Layout = ({ children, siteMetaData, seo, imBusy }) => {
  const location = useLocation()

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Head seo={seo} siteMetaData={siteMetaData} location={location} />
      <Header />
      <AgeVerify />

      <main style={{ marginTop: "100px" }}>{children}</main>
      <Footer />
      <ThirdPartyScripts />
    </div>
  )
}

export default Layout
