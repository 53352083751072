import React, { useCallback, useRef, useState } from "react"
import { AnimatedLink, AnimatedSection } from "@components/shared"
import { INavItem, IMenu, IClickableDiv } from "./types"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"

import {
  menu__open,
  menu__closed,
  menu__nav,
  menu__nav__medias,
  menu__nav__medias__icon,
  menu__nav__list__item,
  menu__nav__list__item__active,
  menu__nav__list__item__dropdown,
  mobile_dropdown,
  mobile_dropdown__open,
  mobile_dropdown__close,
  menu__nav__list__item__dropdown__link,
  menu__nav__list__item__dropdown__link__active,
} from "./header.module.scss"
import Fb from "../../standard/Home/icons/Animated/Fb"

const navLinks: Array<INavItem> = [
  {
    name: "O festiwalu",
    url: "/",
  },
  {
    name: "Program",
    url: "/#program",
  },
  {
    name: "Partnerzy",
    url: "/partnerzy",
  },
  {
    name: "Winnice i Wystawy",
    url: "/winnice",
  },
  // {
  //   name: "Galeria",
  //   url: "/galeria",
  // },
  {
    name: "Kontakt",
    url: "/#kontakt",
  },
  {
    name: "Archiwum",
    url: "/home-2018",
    sublinks: [
      { name: "Galeria 2021", url: "/home-2021/galeria-2021" },
      { name: "Winnice 2021", url: "/home-2021/winnice-2021" },
      { name: "Galeria 2020", url: "/home-2020/galeria-2020" },
      { name: "Winnice 2020", url: "/home-2020/winnice-2020" },
      { name: "Galeria 2018", url: "/home-2018/galeria-2018" },
      { name: "Winnice 2018", url: "/home-2018/winnice-2018" },
    ],
  },
]

// uniwersalny
const ClickableDiv: React.FC<IClickableDiv> = (props) => <div {...props} />

const Menu: React.FC<IMenu> = ({ isMenuOpen, toggleMenu, closeMenu }) => {
  const [sublinksOpen, setSublinksOpen] = useState(false)
  const handleLinkClick = useCallback(
    (e) => {
      if (e.target.hasAttribute("href")) toggleMenu()
      console.log(e.target)
    },
    [isMenuOpen]
  )
  const location = useLocation()

  return (
    <div
      className={isMenuOpen ? menu__open : menu__closed}
      onClick={handleLinkClick}
      // onClick={() => toggleMenu()}
    >
      <div className={menu__nav}>
        <ul>
          {navLinks.map(({ name, url, sublinks }) => {
            return (
              <li key={name}>
                {sublinks ? (
                  <>
                    <ClickableDiv
                      className={
                        location.pathname === url ||
                        location.pathname + location.hash === url
                          ? menu__nav__list__item__active
                          : menu__nav__list__item__dropdown
                      }
                      onClick={() => setSublinksOpen(!sublinksOpen)}
                    >
                      {name}
                    </ClickableDiv>
                    <ul
                      className={`${mobile_dropdown} ${
                        sublinksOpen
                          ? mobile_dropdown__open
                          : mobile_dropdown__close
                      }`}
                    >
                      {sublinks.map((sublink) => (
                        <>
                          <Link
                            to={sublink.url}
                            key={sublink.url}
                            className={`${menu__nav__list__item__dropdown__link} ${
                              location.pathname === sublink.url &&
                              location.pathname + location.hash ===
                                sublink.url &&
                              menu__nav__list__item__dropdown__link__active
                            }`}
                          >
                            {sublink.name}
                          </Link>
                        </>
                      ))}
                    </ul>
                  </>
                ) : (
                  <Link
                    to={url}
                    className={
                      location.pathname === url ||
                      location.pathname + location.hash === url
                        ? menu__nav__list__item__active
                        : menu__nav__list__item
                    }
                  >
                    {name}
                  </Link>
                )}
              </li>
            )
          })}
        </ul>
        <div className={menu__nav__medias}>
          <a
            href="https://www.facebook.com/FestiwalWina/"
            className={menu__nav__medias__icon}
            target="_blank"
          >
            <Fb />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Menu
