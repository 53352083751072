import React from "react"

const Fb = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.384 0 0 5.384 0 12C0 18.616 5.384 24 12 24C18.616 24 24 18.616 24 12C24 5.384 18.616 0 12 0ZM12 2C17.535 2 22 6.465 22 12C22.0024 14.394 21.144 16.709 19.5813 18.5226C18.0187 20.3362 15.856 21.5275 13.488 21.879V14.916H16.336L16.783 12.023H13.488V10.443C13.488 9.243 13.883 8.176 15.006 8.176H16.811V5.652C16.494 5.609 15.823 5.516 14.555 5.516C11.907 5.516 10.355 6.914 10.355 10.1V12.023H7.633V14.916H10.355V21.854C8.01975 21.4696 5.89724 20.2675 4.36669 18.4623C2.83615 16.6572 1.99729 14.3667 2 12C2 6.465 6.465 2 12 2Z"
        fill="#26262F"
      />
    </svg>
  )
}

export default Fb
