import React from "react"
import {
  container,
  container__content,
  container__content__partner,
} from "./style/vineyards.module.scss"
import { useStaticQuery, graphql } from "gatsby"

const Vineyards: React.FC = (): JSX.Element => {
  const { WP } = useStaticQuery(graphql`
    {
      WP {
        page(id: "cG9zdDo5OTY=") {
          ACF_Vineyards {
            vineyards {
              url
              title
              textContentOne
              textContentTwo
              textContentThree
              textContentFour
              textContentFive
              textContentSix
              textContentSeven
              textContentEight
              siteUrlLabel
              siteUrl
            }
          }
        }
      }
    }
  `)

  const { vineyards } = WP.page.ACF_Vineyards

  return (
    <section className={container}>
      <div className={container__content}>
        {vineyards &&
          vineyards.length > 0 &&
          vineyards.map((el, i) => (
            <div key={i} className={container__content__partner}>
              <img src={el.url} alt="partner-logo" loading="lazy" />
              <div>
                {el.title && <h3>{el.title}</h3>}
                {el.textContentOne && (
                  <p dangerouslySetInnerHTML={{ __html: el.textContentOne }} />
                )}
                {el.textContentTwo && <p>{el.textContentTwo}</p>}
                {el.textContentThree && <p>{el.textContentThree}</p>}
                {el.textContentFour && <p>{el.textContentFour}</p>}
                {el.textContentFive && <p>{el.textContentFive}</p>}
                {el.textContentSix && <p>{el.textContentSix}</p>}
                {el.textContentSeven && <p>{el.textContentSeven}</p>}
                {el.textContentEight && <p>{el.textContentEight}</p>}
                {el.siteUrl && (
                  <a href={el.siteUrl}>
                    {el.siteUrlLabel ? el.siteUrlLabel : el.siteUrl}
                  </a>
                )}
              </div>
            </div>
          ))}
      </div>
    </section>
  )
}

export default Vineyards
