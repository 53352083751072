import React from "react"
import { WPApi } from "@api"
import is from "is_js"
import { button__primary } from "@styles/button.module.scss"
import { newsletter } from "./styles/newsletter.module.scss"

class NewsletterForm extends React.Component {
  state = {
    email: "",
    msg: "",
    imBusy: false,
  }

  action = () => {
    if (is.email(this.state.email) === true) {
      this.setState({
        imBusy: true,
      })
      WPApi.sendMail(this.state.email).then(({ message }) => {
        this.setState({
          email: "",
          msg: message,
          imBusy: false,
        })
      })
    }
  }

  render() {
    if (this.state.imBusy) {
      return (
        <div className={form}>
          <h6 className={form__msg}>Dodaję Cię do listy mailingowej</h6>
        </div>
      )
    }
    if (this.state.msg != "") {
      return (
        <div className={form}>
          <h6 className={form__msg}>{this.state.msg}</h6>
        </div>
      )
    }

    const {
      title = "Zapisz się do naszego newslettera i nigdy nie przegap nowych artykułów",
    } = this.props

    return (
      <div className={newsletter}>
        <h2>{title}</h2>
        <form>
          <label htmlFor="email">
            {/* error={errorElements.includes('email') && 'error'} */}
            {/* onInvalid={handleInvalidInput} onChange={handleChange} */}
            <input
              required
              type="text"
              name="email"
              id="email"
              value={this.state.email}
            />
            <span>E-mail</span>
          </label>
          <button className={button__primary} type="submit">
            Zapisz się!
          </button>
        </form>
      </div>
    )
  }
}

export const NewsletterSection = () => <NewsletterForm />

export default NewsletterSection
