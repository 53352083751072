import { Link } from "gatsby"
import React from "react"
import {
  container,
  container__content,
} from "./style/oldcontentlinks.module.scss"

const OldContentLinks: React.FC = (): JSX.Element => {
  return (
    <section className={container}>
      <div className={container__content}>
        <span>
          Sprawdź galerię z 2021 roku{" "}
          <Link to="/home-2021/galeria-2021">TUTAJ</Link>
        </span>
        <span>
          Sprawdź winnice z 2021 roku{" "}
          <Link to="/home-2021/winnice-2021">TUTAJ</Link>
        </span>
        <span>===</span>
        <span>
          Sprawdź galerię z 2020 roku{" "}
          <Link to="/home-2020/galeria-2020">TUTAJ</Link>
        </span>
        <span>
          Sprawdź winnice z 2020 roku{" "}
          <Link to="/home-2020/winnice-2020">TUTAJ</Link>
        </span>
        <span>===</span>
        <span>
          Sprawdź galerię z 2018 roku{" "}
          <Link to="/home-2018/galeria-2018">TUTAJ</Link>
        </span>
        <span>
          Sprawdź winnice z 2018 roku{" "}
          <Link to="/home-2018/winnice-2018">TUTAJ</Link>
        </span>
      </div>
    </section>
  )
}

export default OldContentLinks
