import React from "react"
import {
  container,
  container__content,
  container__content__partner,
} from "./style/vineyards.module.scss"
import { useStaticQuery, graphql } from "gatsby"

const Vineyards: React.FC = (): JSX.Element => {
  const { WP } = useStaticQuery(graphql`
    {
      WP {
        page(id: "cG9zdDoxMzA=") {
          ACF_Vineyards {
            vineyards {
              url
              title
              textContentTwo
              textContentThree
              textContentOne
              textContentFour
              siteUrlLabel
              siteUrl
            }
          }
        }
      }
    }
  `)

  const { vineyards } = WP.page.ACF_Vineyards

  return (
    <section className={container}>
      <div className={container__content}>
        {vineyards.map((el, i) => (
          <div key={i} className={container__content__partner}>
            <img src={el.url} alt="partner-logo" loading="lazy" />
            <div>
              {el.title && <h3>{el.title}</h3>}
              {el.textContentOne && <p>{el.textContentOne}</p>}
              {el.textContentTwo && <p>{el.textContentTwo}</p>}
              {el.textContentThree && <p>{el.textContentThree}</p>}
              {el.textContentFour && <p>{el.textContentFour}</p>}
              {el.siteUrl && (
                <a href={el.siteUrl}>
                  {el.siteUrlLabel ? el.siteUrlLabel : el.siteUrl}
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Vineyards
