import React from "react"
import {
  hero,
  hero__content,
  hero__additional__event,
} from "./style/hero.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { useSpring, animated } from "react-spring"

const AnimatedText = ({ children, className }) => {
  const styles = useSpring({
    opacity: 1,
    delay: 500,
    marginTop: "0px",
    from: { opacity: 0, marginTop: "50px" },
  })

  return (
    <animated.div style={styles} className={className}>
      {children}
    </animated.div>
  )
}

const bottleUrl =
  "https://cannable.api.localhost-group.com/wp-content/uploads/2021/04/woreczek_cropped.png"
const Hero: React.FC = (): JSX.Element => {
  const { WP } = useStaticQuery(graphql`
    {
      WP {
        page(id: "cG9zdDo5ODk=") {
          ACF_Festival {
            subtitleFive
            subtitleFour
            subtitleOne
            subtitleSeven
            subtitleSix
            subtitleThree
            title
            subtitleTwo
          }
          ACF_Additional_Festival {
            additionalFestivalTitle
            additionalFestivalDate
            additionalFestivalSubtitleOne
            additionalFestivalSubtitleTwo
            additionalFestivalSubtitleThree
            additionalFestivalSubtitleFour
            additionalFestivalSubtitleFive
            additionalFestivalSubtitleSix
            additionalFestivalEventLink
            additionalFestivalDetails {
              firstDay {
                title
                firstParagraph
                secondParagraph
                thirdParagraph
              }
              secondDay {
                title
                firstParagraph
                secondParagraph
                thirdParagraph
              }
            }
          }
        }
      }
    }
  `)

  const {
    subtitleFive,
    subtitleFour,
    subtitleOne,
    subtitleSeven,
    subtitleSix,
    subtitleThree,
    title,
    subtitleTwo,
  } = WP.page.ACF_Festival

  const additionalEvent = WP.page.ACF_Additional_Festival

  return (
    <section className={hero}>
      <AnimatedText className={hero__content}>
        <h3>{additionalEvent.additionalFestivalTitle}</h3>
        <h3>{additionalEvent.additionalFestivalDate}</h3>
        <p>{additionalEvent.additionalFestivalSubtitleOne}</p>
        <p>{additionalEvent.additionalFestivalSubtitleTwo}</p>
        <p>{additionalEvent.additionalFestivalSubtitleThree}</p>
        <p>{additionalEvent.additionalFestivalSubtitleFour}</p>
        <p>{additionalEvent.additionalFestivalSubtitleFive}</p>
        <p>{additionalEvent.additionalFestivalSubtitleSix}</p>
        <p>
          Link do wydarzenia:{" "}
          <a href={additionalEvent.additionalFestivalEventLink} target="_blank">
            Kliknij tutaj
          </a>
        </p>
        <div className={hero__additional__event}>
          <p>
            <span>
              {additionalEvent.additionalFestivalDetails.firstDay.title}
            </span>
          </p>
          <p>
            {additionalEvent.additionalFestivalDetails.firstDay.firstParagraph}
          </p>
          <p>
            {additionalEvent.additionalFestivalDetails.firstDay.secondParagraph}
          </p>
          <p>
            {additionalEvent.additionalFestivalDetails.firstDay.thirdParagraph}
          </p>
        </div>
        <div className={hero__additional__event}>
          <p>
            <span>
              {additionalEvent.additionalFestivalDetails.secondDay.title}
            </span>
          </p>
          <p>
            {additionalEvent.additionalFestivalDetails.secondDay.firstParagraph}
          </p>
          <p>
            {
              additionalEvent.additionalFestivalDetails.secondDay
                .secondParagraph
            }
          </p>
        </div>
      </AnimatedText>
      <AnimatedText className={hero__content}>
        <h3>{title}</h3>
        <p>{subtitleOne}</p>
        <p>{subtitleTwo}</p>
        <p>{subtitleThree}</p>
        <p>{subtitleFour}</p>
        <p>{subtitleFive}</p>
        <p>{subtitleSix}</p>
        <p>{subtitleSeven}</p>
      </AnimatedText>
    </section>
  )
}

export default Hero
