import React, { useEffect, useState } from "react"
import {
  container,
  container__content__opinions,
  container__content,
  container__content__opinions__selected,
  bigImage,
} from "./style/headerslider.module.scss"
import Slider from "react-slick"
import { ResizedImage } from "@components/shared"
import { useStaticQuery, graphql } from "gatsby"

const HeaderSlider: React.FC = (): JSX.Element => {
  const [size, setSize] = useState("300w")

  const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          className: "center",
          centerMode: true,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  }

  const { WP } = useStaticQuery(graphql`
    {
      WP {
        page(id: "cG9zdDo2ODE=") {
          ACF_HeaderSlider {
            slider {
              title
              subtitle
              url {
                sourceUrl
                mediaDetails {
                  height
                  width
                }
                srcSet
              }
            }
          }
        }
      }
    }
  `)

  const { slider } = WP.page.ACF_HeaderSlider
  return (
    <section className={container}>
      <div className={container__content}>
        <Slider
          {...settings}
          className={container__content__opinions}
          arrows={false}
          // afterChange={(ev)=>{setCurrent(ev)}}
        >
          {slider.map((el, i) => (
            <Slide el={el} key={i} index={i} size={size} />
          ))}
        </Slider>
      </div>
    </section>
  )
}

const Slide: React.FC = ({ el, index, size }): JSX.Element => {
  return (
    <div
      className={`${container__content__opinions__selected} ${
        index === 0 && bigImage
      }`}
    >
      <ResizedImage {...el.url} size="1024w" />
      <div>
        <div>
          <h1>{el.title}</h1>
          <h2>{el.subtitle}</h2>
        </div>
      </div>
    </div>
  )
}

export default HeaderSlider
