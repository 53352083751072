import React from "react"
import { useSpring, animated } from "react-spring/"
import { useIntersectionObserver } from "@components/logic"

import { IAnimatedSection } from "./types"

const randomizer = (border = 40) => -1 * border + 2 * Math.random() * border

const animationStates = (inView: boolean) =>
  !inView
    ? {
        opacity: 0,
        transform: randomizer(),
      }
    : {
        opacity: 1,
        transform: 0,
      }

const Section: React.FC<IAnimatedSection> = ({
  children,
  className,
  ...rest
}) => {
  const [{ inView }, marktoObserve] = useIntersectionObserver()
  const { opacity, transform } = useSpring({
    config: { mass: 1, tension: 400, friction: 50 },
    ...animationStates(inView),
  })

  return (
    <animated.div
      {...rest}
      ref={marktoObserve}
      className={className}
      style={{
        transform: inView
          ? transform.interpolate((x) => `translateX(${x}%)`)
          : "none",
        opacity: inView ? opacity.interpolate((x) => x) : 0,
      }}
    >
      {children}
    </animated.div>
  )
}

export default Section
