import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import { Formik } from "formik"
import * as Yup from "yup"
import { overlay, modal, close } from "./style/newsletter.module.scss"
import CloseIcon from "./icons/close.svg"
import { WPApi } from "@api"

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
})

const Newsletter: React.FC = (): JSX.Element => {
  const [isOpen, setOpen] = useState(false)

  const closeModal = () => {
    setOpen(false)
    sessionStorage.setItem("newsletter", "true")
  }

  //   LOGIKA WYŚWIETLANIA MODALA Z NEWSLETTEREM
  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      if (
        localStorage.getItem("ageVerify") &&
        !localStorage.getItem("newsletter")
      )
        if (!sessionStorage.getItem("newsletter")) {
          window.addEventListener(
            "scroll",
            () =>
              setTimeout(() => {
                setOpen(true)
              }, 7000),
            {
              once: true,
            }
          )
        }
    }
  })

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={modal}
      overlayClassName={overlay}
    >
      <Formik
        initialValues={{ email: "" }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          localStorage.setItem("newsletter", "true")
          console.log(values)
          closeModal()
          WPApi.sendNewsletter(values.email).then((data) => {
            console.log("WYSŁANO")
          })
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <>
            <button className={close} onClick={closeModal}>
              <img src={CloseIcon} alt="close" />
            </button>
            <h2>
              Zostaw nam swojego ulubionego maila, abyśmy mogli na bieżąco
              informować Cię o aktualnościach oraz o programie Festiwalu Wina
              2021!
            </h2>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Miejsce na Twojego ulubionego maila"
              />
              <button type="submit">Informuj mnie na bieżąco</button>
            </form>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default Newsletter
