import { ScriptAfterInteraction } from "@components/logic"
import React from "react"

const ThirdPartyScripts = () => (
  <>
    <ScriptAfterInteraction
      id="gtag"
      async
      defer
      type="text/javascript"
      children={gtagScripts}
    />
    <ScriptAfterInteraction
      id="glm"
      async
      defer
      src="https://www.googletagmanager.com/gtag/js?id=UA-174996839-1"
    />
    <ScriptAfterInteraction
      async
      defer
      type="text/javascript"
      src="https://apis.google.com/js/api.js"
    />
    <ScriptAfterInteraction
      async
      defer
      crossorigin="anonymous"
      src="https://connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v10.0"
      nonce="2kvxfFk7"
    />
  </>
)

export default ThirdPartyScripts

const gtagScripts = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-174996839-1');
    `
