import React, { useState } from "react"
import {
  container,
  container__opinions,
  container__opinions__selected,
  container__header,
} from "./style/partnersslider.module.scss"
import Slider from "react-slick"
import PartnersIcon from "./icons/partners.svg"
import { useStaticQuery, graphql } from "gatsby"

const PartnersSlider2018: React.FC = (): JSX.Element => {
  const [imageIndex, setImageIndex] = useState(0)
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  }

  const { WP } = useStaticQuery(graphql`
    {
      WP {
        page(id: "cG9zdDoxNjA=") {
          ACF_Partners {
            partner {
              url
            }
          }
        }
      }
    }
  `)

  const { partner } = WP.page.ACF_Partners
  const SliderImages = [...partner]

  return (
    <section className={container}>
      <div className={container__header}>
        <img src={PartnersIcon} alt="partners" loading="lazy" />
        <h3>Partnerzy</h3>
      </div>
      <Slider {...settings} className={container__opinions} arrows={false}>
        {SliderImages.map((el, i) => (
          <div className={container__opinions__selected} key={i}>
            <img src={el.url} alt="partner-logo" loading="lazy" />
          </div>
        ))}
      </Slider>
    </section>
  )
}

export default PartnersSlider2018
