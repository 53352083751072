import React from "react"
import { hero, hero__content } from "./style/hero.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { useSpring, animated } from "react-spring"

const AnimatedText = ({ children, className }) => {
  const styles = useSpring({
    opacity: 1,
    delay: 500,
    marginTop: "0px",
    from: { opacity: 0, marginTop: "50px" },
  })

  return (
    <animated.div style={styles} className={className}>
      {children}
    </animated.div>
  )
}

const bottleUrl =
  "https://cannable.api.localhost-group.com/wp-content/uploads/2021/04/woreczek_cropped.png"
const Hero: React.FC = (): JSX.Element => {
  const { WP } = useStaticQuery(graphql`
    {
      WP {
        page(id: "cG9zdDo2ODE=") {
          ACF_Festival {
            subtitleFive
            subtitleFour
            subtitleOne
            subtitleSeven
            subtitleSix
            subtitleThree
            title
            subtitleTwo
          }
        }
      }
    }
  `)

  const {
    subtitleFive,
    subtitleFour,
    subtitleOne,
    subtitleSeven,
    subtitleSix,
    subtitleThree,
    title,
    subtitleTwo,
  } = WP.page.ACF_Festival

  return (
    <section className={hero}>
      <AnimatedText className={hero__content}>
        <h3>{title}</h3>
        <p>{subtitleOne}</p>
        <p>{subtitleTwo}</p>
        <p>{subtitleThree}</p>
        <p>{subtitleFour}</p>
        <p>{subtitleFive}</p>
        <p>{subtitleSix}</p>
        <p>{subtitleSeven}</p>
      </AnimatedText>
    </section>
  )
}

export default Hero
